



@media (max-width: 700px) and (max-height: 830px) {
    .top_menu {
        opacity: 0;
        margin-top: 0;
    }
}

@media (min-height: 500px) and (max-height: 830px) and (max-width: 700px) {
    .top_header {
        height: 7vh;
    }
    .top_menu {
        height: 7vh;
    }

    .svgUser {
        width: 6vh;
        height: 6vh; 
    }
    .side_header {
        top: 7vh;
        height: 93vh;
    }
    .windowUnderLogo {
        top: 7vh
    }
}

@media (min-height: 300px) and (max-height: 500px) {
    .top_header {
        height: 9vh;
    }
    .top_menu {
        height: 9vh;
    }
    .hight_logo {
        padding-top: 6px;
    }
    .svgUser {
        width: 7vh;
        height: 7vh; 
    }
    .userMenu p {
        margin-top: 8px;
    }

    .arrow {
        margin-top: 7px;
    }

    .side_header {
        top: 9vh;
        height: 91vh;
    }
    .windowUnderLogo {
        top: 9vh
    }
}

@media (max-height: 300px) {
    .top_header {
        height: 10vh;
    }
    .top_menu {
        height: 10vh;
    }
    .svgUser {
        width: 8vh;
        height: 8vh; 
    }
    .userMenu p {
        font-size: 13px;
        margin-top: 7px;
    }
    .arrow {
        width: 7vh;
        height: 7vh; 
        margin-top: 6px;
    }
    .hight_logo {
        padding-top: 6px;
        font-size: 15px;
    }
    .side_header {
        top: 10vh;
        height: 90vh;
    }
    .windowUnderLogo {
        top: 10vh
    }
}




@media (max-width: 1200px) {
    .buttonLeftForTopMenu, .buttonRightForTopMenu {
        display: none;
    }
}
@media (min-width: 350px) and (max-width: 1418px) {
    .userMenu {
        margin-right: 1vw;
    }
}
@media (min-width: 750px) and (max-width: 1030px) {
    .side_header {
        width: 4vw;
    }
    .side_header:hover {
        width: 23vw;
    }
}
@media (min-width: 550px) and (max-width: 750px) {
    .side_header {
        width: 6vw;
    }
    .side_header:hover {
        width: 30vw;
    }
}
@media (min-width: 450px) and (max-width: 550px) {
    .side_header {
        width: 6.5vw;
    }
    .side_header:hover {
        width: 32vw;
    }
}
@media (min-width: 350px) and (max-width: 450px) {
    .side_header {
        width: 7vw;
    }
    .side_header:hover {
        width: 45vw;
    }
    .menuSimbol {
        margin-left: 4px;
    }
}
@media (max-width: 350px) {
    .userMenu {
        margin-right: 0px;
        padding-left: 10px;
    }
    .side_header {
        width: 10vw;
    }
    .side_header:hover {
        width: 45vw;
    }
    .menuSimbol {
        margin-left: 2px;
    }
}
@media (max-height: 830px) {
    .svgUser {
        width: 4vh;
        height: 4vh; 
    }
    .userMenu p, .arrow {
        margin-top: 8px;
    }
}