/*
@media (min-width: 400px) and (max-width: 415px) {
    .wrapperEntrance {
        margin-left: 50px;
    }
}
*/
@media (max-width: 1418px) {
    [class^= diagram] {
        overflow-x: scroll;
        margin-left: 6vw;
    }
    .description {
        padding-left: 29vw;
    }
   /* [class^= diagram] svg {
        width: 1600px;
    }*/
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .description {
        font-size: 3vw;
        padding-top: 5px;
    }
}
@media (min-width: 1200px) and (max-width: 1418px) {
    .description {
        font-size: 2.5vw;
        padding-top: 5px;
    }
}


