.WeHelpDiagramPie, .WeHelpDiagramPie path, .WeHelpDiagramPie text {
    transition: opacity 1s;
}
.WeHelpDiagramPie text {
    fill: white;

}
.WeHelpDiagramLine2 {
    margin-left: 0px;
}
.subscriptionOfPoint_WeHelpDiagramLine2 {
    stroke-width: 0.5px;
    stroke: #252733;
    
}
.subscriptionOfGrafik_WeHelpDiagramLine2 {
    font-size: 24px;

}
.subscriptionOfDate_WeHelpDiagramLine2 {
    transform-box: content-box;
    transform-origin: right top;
    transform: rotate(-50deg);
    
    font-size: 12px;
}
.diagramWrapping_for_multipleLine {
    overflow: visible;
    padding-top: 20px;
    padding-left: 0px;
    margin-top: 25px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}

/* transform-origin: right top; */



.buttonZoomPlus_WeHelpDiagramLine2, .buttonZoomMinus_WeHelpDiagramLine2 {
    height: 29px;
    width: 29px;
    border: 1px solid silver;
    cursor: pointer;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    color: silver;
    margin-left: 82vw;
    opacity: 0.5;
}
.buttonZoomPlus_WeHelpDiagramLine2 {
    transform: translateY(-11vh) translateX(9vw);

    padding-left: 7px;
    padding-top: 1px;
}
.buttonZoomMinus_WeHelpDiagramLine2 {
    transform: translateY(-10vh) translateX(9vw);
    padding-left: 5px;
    padding-top: 1px;
}
.buttonZoomPlus_WeHelpDiagramLine2:hover, .buttonZoomMinus_WeHelpDiagramLine2:hover {
    opacity: 1;
    border-color: #FFAA20;
    color:#FFAA20;
    box-shadow: 7px 5px 5px silver;
}
.buttonZoomPlus_WeHelpDiagramLine2:active, .buttonZoomMinus_WeHelpDiagramLine2:active {
    box-shadow: 3px 5px 5px silver;
}

