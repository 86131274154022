
.homepage {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 30vw;
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    column-gap: 50px;
    row-gap: 20px;
    margin-top: 100px;
    height: 50vh;
    font-family: Sarabun, sans-serif;
    font-size: 17px;
}

.home_page_table1 {
    grid-column: 1;
    grid-row: 1;
}
.home_page_table2 {
    grid-column: 1;
    grid-row: 2;
}
.home_page_table3 {
    grid-column: 2;
    grid-row: 1;
}
.home_page_table4 {
    grid-column: 2;
    grid-row: 2;
}

.home_page_table1, .home_page_table2 {
    padding-left: 15px;
    padding-right: 10px;
    width: 520px;
    height: 140px;
}

.home_page_table1_header, .home_page_table1_text,
.home_page_table1_links, .home_page_table2_header, 
.home_page_table2_text, .home_page_table1_links {
    margin-top: 15px;
}
.home_page_table1_links a, .home_page_table2_links a {
    color: #007bff;
    text-decoration: none;
    margin-right: 15px;
}
.home_page_table1_links, .home_page_table2_links {
    padding-top: 5px;
}
.home_page_table1_links a:hover, .home_page_table2_links a:hover {
    text-decoration: underline;
}


.home_page_table3, .home_page_table4 {
    padding-left: 15px;
    width: 550px;
    height: 200px;
}

.home_page_table1, .home_page_table2,
.home_page_table3, .home_page_table4 {
    background-color: white;
    box-shadow: 2px 0px 5px 0 gray;
}
.home_page_table3_header, .home_page_table4_header {
    padding-top: 5px;
    height: 30px;
    box-shadow: 0px 2px 1px 0 silver;
    margin-bottom: 15px;
}
.home_page_table2, .home_page_table4 {
    border-top: 3px solid #007bff;
}
.home_page_table3_text, .home_page_table4_text {
    margin-bottom: 15px; 
}
.home_page_table3 button, .home_page_table4 button {
    background-color: #007bff;
    color: white;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    
    border-radius: 3px;
    height: 50px;
    width: 140px;
}
.home_page_table3 button {
    top: 44px;
}
.home_page_table4 button {
    top: 25px;
}
.home_page_table3 button:active , .home_page_table4 button:active {
    box-shadow: inset 5px 5px 1px 0 #0000CD;
}
