


/*
Здесь начинаются стили календаря
*/

.calendarFormTable {
    display: flex;
}
.calendarFormTable .air-datepicker {
    margin-left: auto;
    margin-right: 10vw;
}


