
.my-div-icon {

color: white;
font-size: 20px;

}
.mycluster {

    color: white;
    font-size: 20px;
  
}

.containerWithMarkerNames {

width: 10vw;
min-height: 10vh;
padding: 10px 0px 10px 10px;
transform: translateX(3vw) translateY(-73px);
display: flex;
flex-direction: column;
background-color: #f8f8f8;
opacity: 0.7;

}

.child_containerWithMarkerNames {
font-size: 20px;
flex-grow: 1;
opacity: 1;

}


