* {
box-sizing: border-box;
padding: 0px;
margin: 0px;
}
.AuthorizationWrapper {
    margin-top: 5vh;
}
.LeftSidebar {
    width: 35vw;
    overflow: hidden;
    
}
.RightSidebar {
    width: 65vw;
    background-color: whitesmoke;
}

.Sidebars {
    height: 95vh;
    display: flex;
}


.Sidebarswrapper {
    height: 95vh;
    width: 105vw;
    display: flex;
    scroll-behavior: smooth;
    transition: margin-left 500ms;
}
.SlideContainer {
    padding: 0;
}
.OneLeftSidebar {
    
    height: 95vh;
    width: 35vw;
    background: linear-gradient(135deg, #6610f2, #6f42c1);


}

.TwoLeftSidebar {

    height: 95vh;
    width: 35vw;
    background: linear-gradient(135deg, #6c757d, #343a40);


}

.ThreeLeftSidebar {

    height: 95vh;
    width: 35vw;
    background: linear-gradient(1135deg, #ffc107, #fd7e14);

    
}

.LeftButton, .RightButton {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    background-color: white;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.2;
    transition: opasity 100ms;
}

.LeftButton:hover, .RightButton:hover,
.LeftButton:active, .RightButton:active {
    opacity: 0.5;
}
.LeftButton {
    left: 1vw;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;
}
.RightButton {
    right: 66vw;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;
}
.wrapperEntrance {
    height: 45vh;
    width: 45vw;
    margin-top: 25vh;
    margin-left: 10vw;
    padding: 45px;
}

.logo {
    width: 50px;
    height:50px;
    
    margin-bottom: 15px;
    font-family: Sarabun, sans-serif;
    font-weight: bolder;
    font-size: 23px;
}
.greet {
    width: 450px;
    height: 75px;
    border-bottom: 1px solid #6c757d;
    padding-top: 15px;
}
.greet div {
    opacity: 0.4;
    font-family: Sarabun, sans-serif;
    font-weight: bolder;
    font-size: 24px;
}
.greet span {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    margin-top: 10px;
    margin-right: 5px;
    color: #6c757d;
}
.greet a {
    text-decoration: none;
    font-size: 16px;
}
.greet a:hover {
    text-decoration: underline;
}
.signinForm {
 
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
}

.label1 {
    grid-row: 1;
    grid-column: 1;
}
.input1 {
    grid-row: 2;
    grid-column: 1;
    height: 30px;
    width: 300px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
}
.label2 {
    grid-row: 1;
    grid-column: 2;
}
.input2 {
    grid-row: 2;
    grid-column: 2;
    height: 30px;
    width: 300px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
}
.signinForm div {
    grid-row: 3;
    grid-column: 1;
}
.input4 {
    grid-row: 3;
    grid-column: 2;
    width: 300px;
}
.input3 {
    margin-right: 4px;
}

.modalWindow {
    position: absolute;
    background-color: silver;
    box-shadow: 5px 5px 5px 0 gray;
    font-family: Sarabun, sans-serif;
    width: 27vw;
    height: 15vh;
    top: 5vh;
    left: 35vw;
    opacity: 1;
}
.modalWindow_text {
    text-align: center;
    font-size: 24px;
    color: white;
    padding-top: 15px;
}

.modalWindow div button {
    border-radius: 3px;
    border: 1px groove hsla(0,0%,75%, 1);
    width: 90px;
    height: 25px;
    margin-top: 6vh;
    margin-left: 20vw;
}

.modalWindow div button:active {
    box-shadow: inset 5px 5px 1px 0 gray;
}




@media (max-width: 467px) {
    .LeftSidebar {
        display: none;
    }
    .RightSidebar {
        width: 100vw;
    }
}
@media (min-width: 200px) and (max-width: 1090px) {
    .wrapperEntrance {
        margin-top: 15vh;
        padding: 0px;
    }
    .greet {
        height: 90px;
        width: 300px;
    }
    .label1 {
        grid-row: 1;
        grid-column: 1;
    }
    .input1 {
        grid-row: 2;
        grid-column: 1;
    }
    .label2 {
        grid-row: 3;
        grid-column: 1;
    }
    .input2 {
        grid-row: 4;
        grid-column: 1;
    }
    .signinForm div {
        grid-row: 5;
        grid-column: 1;
    }
    .input4 {
        grid-row: 6;
        grid-column: 1;
    }
}

.SwitchToTestServer {
    transform: translateY(30px);
}
.SwitchToMap {
    transform: translateY(60px);
}

@media (max-width: 400px) {
    .input1, .input2, .input4 {
        width: 240px;
    }
}
@media (max-width: 400px) {
    .greet {
        width: 240px;
    }
}
@media (min-width: 300px) and (max-width: 400px) {
    .wrapperEntrance {
        margin-left: 8.5vh;
    }
}
@media (min-width: 400px) and (max-width: 415px) {
    .wrapperEntrance {
        margin-left: 50px;
    }
}
@media (min-width: 415px) and (max-width: 540px) {
    .greet {
        width: 250px;
    }
    .input1, .input2, .input4 {
        width: 250px;
    }

}
@media (min-width: 541px) and (max-width: 700px) {
    .greet {
        height: 90px;
        width: 300px;
    }
    .input1, .input2, .input4 {
        width: 300px;
    }

}
@media (min-width: 701px) and (max-width: 900px) {
    .wrapperEntrance {
        margin-top: 17vh;
        margin-left: 12.5vw;
    }
}
@media (min-width: 901px) and (max-width: 1000px) {
    .wrapperEntrance {
        margin-top: 19vh;
        margin-left: 14vw;
    }
    .greet {
        height: 130px;
        width: 300px;
    }
    .greet div, .logo {
        font-size: 36px;
    }
    .greet span, .greet a {
        font-size: 26px;
    }
    .label1, .label2, .lable3 {
        font-size: 26px;
    }
    .input3 {
        margin-right: 7px;
    }
}
@media (min-width: 1000px) and (max-width: 1090px) {
    .wrapperEntrance {
        margin-top: 8vh;
        margin-left: 13.5vw;
    }
}
@media (min-width: 1091px) and (max-width: 1200px) {
    .wrapperEntrance {
        margin-top: 8vh;
        margin-left: 3vw;
    }
    .modalWindow div button {
        margin-top: 4vh;
        margin-left: 18vw;
    }
}
@media (min-width: 1201px) and (max-width: 1285px) {
    .wrapperEntrance {
        margin-top: 15vh;
        margin-left: 0.75vw;
    }
}
@media (max-height: 800px) {
    .RightSidebar {
        overflow-y: auto;
    }
    .wrapperEntrance {
        padding-bottom: 15px;
    }
}
