
.FormTable {
    min-height: 90vh;
    width: 100vw;
    background-color: whitesmoke;
    padding-top: 50px;
    padding-bottom: 10px;
}



.groupFormTable {
    width: 100vw;
    padding-left: 10vw;
    margin-bottom: 30px;
}
.wrappingFormTable {
    width: 80vw;
    height: 64px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
    margin-bottom: 5px;
}
/*
.wrappingFormTableWithTextArea {
    overflow: hidden;
    display: inline-block;
}
*/
.wrappingFormTable input, .wrappingFormTable textarea {
    width: 90%;
    height: 35px;
    margin-top: 5px;
    margin-left: 10px;
    outline: 0;
    border: none;
    font-size: 20px;
    background-color: inherit;
}
.wrappingFormTable textarea {
    width: 98%;
    resize: none;

    transition: none;
}

.wrappingFormTable .inactiveinputFormTable {
    display: none;
}
.wrappingFormTable p {
    transition: 
    font-size 1s,
    margin-top 1s,
    margin-left 1s;
}
.activeparagraphFormTable {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 19px;
}

.wrappingFormTable:hover .activeparagraphFormTable, 
.wrappingFormTable:focus-within .activeparagraphFormTable,
.inactiveparagraphFormTable {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}

.wrappingFormTable:hover .inactiveinputFormTable {
    display: block;
}
.wrappingFormTable:focus-within .inactiveinputFormTable {
    display: block;
}
.activeinputFormTable {
    display: block;
}
.inactiveinputFormTable {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}



.FormTable label p {
    margin-left: 10vw;
    font-size: 20px;
    font-family: Sarabun, sans-serif;
    margin-bottom: 5px;
    cursor: pointer;
}
.FormTable label input {
    opacity: 0;
    position: absolute;
    left: -9999px;
}
.FormTable label:has(input:checked) + .groupFormTable {
    height: 0;
    overflow: hidden;
    
}
.FormTable label + .groupFormTable {
    height: auto;
    
}

.colorInput .colorInputDiv {
    display: flex;
}
.wrappingFormTable .colorInputChild1 {
    height: 4vw;
    width: 4vw;
    margin-top: -1vw;
    margin-left: -1vw;


}
.wrappingFormTable .colorInputChild2 {
    width: 70vw;
}

.frameForColorInput {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f66257;
    padding: 0px, 0px, 0px, 0px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.calendarInputDiv {
    display: flex;
}



/*
.ekleFormTable {
    position: absolute;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    border: 1px solid #4867D6;
    border-radius: 8px;
    cursor: pointer;
    right: 3vw;
}
*/

.ekleFormTable {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    border: 1px solid #4867D6;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 3vw;
    margin-bottom: 10px;
}

.ekleFormTable:hover {
    background-color: #4867D6;
    color: azure;
    box-shadow: 3px 5px 5px gray;
}
.ekleFormTable:active {
    box-shadow: 0px 0px 5px gray;
}

.closeFormTable {
    border: 1px solid #f66257;
    display: flex;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    position: absolute;
    right: 3vw;
    transform: translateY(-20px);
    cursor: pointer;
}
.closeFormTable:hover {
    background-color: #f66257;
    box-shadow: 7px 5px 5px silver;
}
.closeFormTable:active {
    box-shadow: 3px 5px 5px silver;
}



.popUpWindow_FormTable {
    position: absolute;
    transform: translateY(9px);
    background-color: silver;
    border-radius: 5px;
    width: 50vw;
    max-height: 25vh;
    min-height: 5vh;
    display: none;
    font-size: inherit;
    font-family: inherit;
    overflow: auto;
}
.popUpWindow_FormTable div {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 5px;
}
.popUpWindow_FormTable div:hover {
    background-color: #DCDCDF;
}
.wrappingFormTable:focus-within .popUpWindow_FormTable {
    display: block;
}

.flagTrueFalse {
    display: flex;
}

.flagTrueFalse p {
    display: flex;
    font-size: 20px;
    margin-left: 10px;
    margin-top: 19px;
}

.flagTrueFalse div {
    position: relative;
    margin-left: auto;
    margin-right: 15px;
    margin-top: 5px;
    z-index: 0;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid #4FB2F5;
}

.svgTrue {
    fill: #00d400;
    width: 80px;
    height: 80px;   
}

.imageWrapping_formtable {
    max-width: 80vw;
    overflow-x: auto;
}
.imagePng_formtable {
    max-width: 80vw;
}
.iapplicationPdf_formtable {
    width: 80vw;
    height: 80vh;
}
.button_iapplicationPdf_formtable, .button_calendar_formtable, .buttonForFileInput {
    min-width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #e3e2e7;
    border-radius: 8px;
    cursor: pointer;
    
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 20px;
}
.button_iapplicationPdf_formtable:hover, .button_calendar_formtable:hover, .buttonForFileInput:hover {
    background-color: #e3e2e7;
    
    box-shadow: 3px 5px 5px gray;
}
.button_iapplicationPdf_formtable:active, .button_calendar_formtable:active, .buttonForFileInput:active {
    box-shadow: 0px 0px 5px gray;
}
.button_calendar_formtable {
    position: absolute;
    right: 10vw;
    transform: translateY(-50px);
}








.groupFormTable .filledField {
    border: 2px solid green;
}
.groupFormTable .emptyField {
    border: 2px solid #f66257;
}
/*
Данные селекторы переопределяют стили полей, в которые обязательно нужно поставить 
какое то значение. Из за того что эти селекторы переопределяют уже существующие стили, 
велика вероятность, что браузер может посчитать приоритет стилей в данных селекторах 
слишком низким и использовать старые стили. Здесь нужно следить, чтобы приоритет данных 
селекторов был выше, чем приоритет переопределяемых селекторов.
*/

.reminderToFillOutAllFields {
    margin-left: 10vw;
    font-size: 24px;
    color: #f66257;
}

.downloadInput {
    position: absolute;
    left: -1000px;
}
.wrappingFormTable label {
    display: flex;
    width: 90%;
    height: 35px;
    margin-top: 5px;
    margin-left: 10px;
    outline: 0;
    border: none;
    font-size: 20px;
    background-color: inherit;
}
.buttonForFileInput {
    margin-left: auto;
    width: 180px;
    
}
.shiftY30 {
    transform: translateY(-30px);
}
.shiftY10 {
    transform: translateY(-10px);
}

/*
.mbsc-ic-upload::before {
    content: "\eafc";
}
*/
