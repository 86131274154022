
.my-div-icon {

color: white;
font-size: 20px;

}
.mycluster {

    color: white;
    font-size: 20px;
  
}

.containerWithMarkerNames {

width: 10vw;
min-height: 10vh;
padding: 10px 0px 10px 10px;
transform: translateX(3vw) translateY(-73px);
display: flex;
flex-direction: column;
background-color: #f8f8f8;
opacity: 0.7;

}

.child_containerWithMarkerNames {
font-size: 20px;
flex-grow: 1;
opacity: 1;

}

.MapTable {
    position: absolute;
    transform: translateY(-2vh);
    z-index: 3000;
}
.gearMap {
    position: absolute;
    transform: translateY(-7vh);
    z-index: 3000;
    left: 2vw;
    height: 30px;
    width: 30px;
    fill: none;
    stroke: black;
    cursor: pointer;
}
.gearSwitch {
    position: absolute;
    transform: translateY(-15vh);
    z-index: 3000;
    left: 6vw;
    background-color: #f7f7f7;
    width: 130px;
    height: 90px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

}
.gearSwitch div:hover {
    background-color: #d2e4f8;
}



.Right_Sidebar {
    position: absolute;
    overflow-x: auto;
    overflow-y: auto;
    top: 10vh;
    right: 0px;
    height: 90vh;
    width: 300px;
    background-color: #f7f7f7;
    z-index: 3000;
}

.SidebarBlock {
    height: auto; 
    width: 100%;
    display: flex;

}

.SidebarBlock_right {
    height: 100%;
    width: 50%;
}
/*
.SidebarBlock_right_level2_number1, SidebarBlock_right_level2_number2 {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 30px;
}
*/

.SidebarBlock_right_level2_number1 {
    display: grid;
    width: 100%;
    height: 50%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.sidebarBlock_drive, .sidebarBlock_pause {
    width: 60px;
    height: 20px;
    background-color: white;
    margin-top: 5px;
    margin-left: 5px;
    text-align: center;
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);

}
[class^= sidebarBlock_time] {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    flex-grow: 1;
    padding-top: 4px;

}
.sidebarBlock_drive {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1; 
}
.sidebarBlock_time1 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1; 
}
.sidebarBlock_pause {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2; 
}
.sidebarBlock_time2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2; 
}

/*
.SidebarBlock_right_level2_number2, .SidebarBlock_right_level2_number3 {
    display: flex;
    width: 100%;
    height: 25%;
}


.SidebarBlock_right_level2_number3 {
    padding-bottom: 5px;
}
*/
[class^= progressBar_SidebarBlock] {
    width: 109px;
    height: 16px;
    background-color: #e9ecef;
    border-radius: 5px;
    margin: 5px 0 0 3px;
    text-align: center;
}
.SidebarBlock_right_level2_number2 {
    display: grid;
    width: 100%;
    height: 42%;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-top: 2%;
}

.progressBar_SidebarBlock1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1; 
}
.progressBar_SidebarBlock2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2; 
}
.repairPng {
    height: 16px;
    width: 16px;
    margin-top: 5px;
    margin-left: 4px;

    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1; 
}
.fuelPng {
    height: 25px;
    width: 25px;


    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 2; 
}


.SidebarBlock_left {
    height: 100%;
    width: 50%;
    color: #ffffff;
}
.SidebarBlock_left_level2_number1 {
    margin: 5px 0px 0px 5px;
}
.SidebarBlock_left_level2_number2 {
    display: flex;

}
.arrowTanglePng {
    height: 47px;
    width: 47px;
    margin-top: 3px;
}
.SidebarBlock_left_level2_number2 div {
    font-size: 42px;
    text-align: center;
    flex-grow: 1;
}
.SidebarBlock_left_level2_number3 {
    margin: 0px 0px 5px 5px;
}

.Right_Sidebar_inputDiv {
    width: 90%;
    height: 60px;
    margin-left: 5%;
    margin-top: 5px;
    margin-bottom: 5px;
    border:1px solid #cccccc;
    border-radius: 5px;
    overflow: hidden;
}
.Right_Sidebar_activeDiv {
    font-size: 24px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.Right_Sidebar_inputDiv div {
    transition-property: font-size, margin-left, margin-top, margin-bottom;
    transition-duration: 1s;
}

.Right_Sidebar_inputDiv:focus-within div, 
.Right_Sidebar_inactiveDiv, 
.Right_Sidebar_inputDiv:hover div{
    font-size: 12px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 3px;
}
.Right_Sidebar_input {
    width: 90%;
    border: none;
    height: 30px;
    margin-left: 5%;
    margin-bottom: 3px;
    background-color: #f7f7f7;
    outline: 0;
    font-size: 18px;
}




.SideBarForm {
    position: absolute;
    overflow-x: auto;
    overflow-y: auto;
    top: 10vh;
    height: 90vh;
    width: 400px;
    background-color: #f7f7f7;
    z-index: 2990; 
    transition: right 1s;
}
.fildOfTheSideBarForm {
    width: 98%;
    min-height: 40px;
    margin-left: 1%;
    border: 1px solid #dddddd;
    padding: 8px 1px 1px 5px;
    font-size: 18px;
}
.fildOfTheSideBarForm:nth-child(odd) {
    background-color: #f9f9f9;
}
.fildOfTheSideBarForm:nth-child(even) {
    background-color: #ffffff;
}
.groupNameOfTheSideBarForm {
    width: 98%;
    min-height: 40px;
    margin-left: 1%;
    background-color: #3f51b5;
    color: #ffffff;
    font-size: 18px;
    padding: 8px 1px 1px 5px;
    font-weight: bold;
    cursor: pointer;
}
.conteinerOfTheSideBarForm {
    overflow: hidden;
    height: auto;
    transition: height 1s;
}


.carConteiner {
    background: none;
    border: none;

    
}


.myCar-div-icon {
    width: 100%;
    height: 30px;
    background-color: red;
    clip-path: polygon(20% 100%, 50% 70%, 80% 100%, 50% 0);
    
    /*transform: rotate(30deg);*/
}

.carPlate {
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    text-align: center;
    border: 1px solid #444444;
    border-radius: 5px;
    margin-bottom: 5px;
    color: #f7f7f7;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 3px;
}

.clusterUnit {
    color: #212529;
    font-size: 18px;
    text-align: center;
    padding-top: 1px;
    border-radius: 50%;
    background-color: rgba(110, 204, 57, 0.6);
    outline: 7px solid rgba(181, 226, 140, 0.6)
}

.tooltip-carPlate {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    /*
    Здесь нужен !important, потому что это есдинственный способ 
    перебить стили библиотеки leaflet.
    */
}

/*
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);
    
    clip-path: polygon(20% 0, 50% 30%, 80% 0, 50% 100%);

    clip-path: polygon(20% 100%, 50% 70%, 80% 100%, 50% 0);

*/
