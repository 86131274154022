
.divWorkOrder {
    font-family: Sarabun, sans-serif;
    background-color: whitesmoke;
    padding-bottom: 25px;
    border-bottom: 1px solid black;
}

.topBlock_divWorkOrder {
    width: 100vw;
    height: 15vh;
    display: grid;
    grid-template-columns: 32vw 32vw 200px;
    border-bottom: 1px solid black;
}


.delete_topBlock_divWorkOrder {
    background-color: #f66257;
    grid-column: 3;
}

.add_topBlock_divWorkOrder {
    background-color: #00d400;
    grid-column: 4;
}

.delete_topBlock_divWorkOrder, .add_topBlock_divWorkOrder {
    margin-top: 30px;
    width: 160px;
    height: 54px;
    border-radius: 3px;
    font-size: 20px;
    color: aliceblue;
    border: none;
    cursor: pointer;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.delete_topBlock_divWorkOrder:active, .add_topBlock_divWorkOrder:active {
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}


.templateName_topBlock_divWorkOrder {
    margin-top: 30px;
    grid-column: 2;
    width: 30vw;
    height: 64px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
}



.centralBlock_divWorkOrder {
    width: 100vw;
    height: 25vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 20px;
    padding-top: 50px;
    padding-left: 10vw;
}


.block1_centralBlock_divWorkOrder, .block3_centralBlock_divWorkOrder,
.block2_centralBlock_divWorkOrder, .block4_centralBlock_divWorkOrder {
    width: 35vw;
    height: 64px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
}
.block1_centralBlock_divWorkOrder input, .block3_centralBlock_divWorkOrder input,
.block2_centralBlock_divWorkOrder input, .block4_centralBlock_divWorkOrder input,
.templateName_topBlock_divWorkOrder input {
    width: 90%;
    height: 35px;
    margin-top: 5px;
    margin-left: 10px;
    outline: 0;
    border: none;
    font-size: 20px;
    background-color: inherit;
}

.block1_centralBlock_divWorkOrder {
    grid-column: 1;
    grid-row: 1;
}
.block3_centralBlock_divWorkOrder {
    grid-column: 1;
    grid-row: 2;
}
.block2_centralBlock_divWorkOrder {
    grid-column: 2;
    grid-row: 1;
}
.block4_centralBlock_divWorkOrder {
    grid-column: 2;
    grid-row: 2;
}
/*
.block1_centralBlock_divWorkOrder, .block3_centralBlock_divWorkOrder {
    margin-left: 16vw;
}
*/

.divWorkOrder .inactiveinput {
    display: none;
}
/*
[class^= block] .inactiveinputFormTable  {
    display: none;
}
*/
.block1_centralBlock_divWorkOrder p, .block3_centralBlock_divWorkOrder p,
.block2_centralBlock_divWorkOrder p, .block4_centralBlock_divWorkOrder p,
.templateName_topBlock_divWorkOrder p {
    transition: 
    font-size 1s,
    margin-top 1s,
    margin-left 1s;
}
.divWorkOrder .activeparagraph {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 19px;
}

.block1_centralBlock_divWorkOrder:hover .activeparagraph, .block3_centralBlock_divWorkOrder:hover .activeparagraph,
.block2_centralBlock_divWorkOrder:hover .activeparagraph, .block4_centralBlock_divWorkOrder:hover .activeparagraph,
.templateName_topBlock_divWorkOrder:hover .activeparagraph {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}
.block1_centralBlock_divWorkOrder:focus-within .activeparagraph, .block3_centralBlock_divWorkOrder:focus-within .activeparagraph,
.block2_centralBlock_divWorkOrder:focus-within .activeparagraph, .block4_centralBlock_divWorkOrder:focus-within .activeparagraph,
.templateName_topBlock_divWorkOrder:focus-within .activeparagraph {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}
.block1_centralBlock_divWorkOrder:hover .inactiveinput, .block3_centralBlock_divWorkOrder:hover .inactiveinput,
.block2_centralBlock_divWorkOrder:hover .inactiveinput, .block4_centralBlock_divWorkOrder:hover .inactiveinput,
.templateName_topBlock_divWorkOrder:hover .inactiveinput {
    display: block;
}
.block1_centralBlock_divWorkOrder:focus-within .inactiveinput, .block3_centralBlock_divWorkOrder:focus-within .inactiveinput,
.block2_centralBlock_divWorkOrder:focus-within .inactiveinput, .block4_centralBlock_divWorkOrder:focus-within .inactiveinput,
.templateName_topBlock_divWorkOrder:focus-within .inactiveinput {
    display: block;
}
.divWorkOrder .activeinput {
    display: block;
}
.divWorkOrder .inactiveparagraph {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}


.popUpWindow_divWorkOrder {
    position: absolute;
    transform: translateY(9px);
    background-color: silver;
    border-radius: 5px;
    width: 50vw;
    max-height: 25vh;
    min-height: 5vh;
    display: none;
    font-size: inherit;
    font-family: inherit;
    overflow: auto;
}
.popUpWindow_divWorkOrder div:hover {
    background-color: #DCDCDF;
}
.block1_centralBlock_divWorkOrder:focus-within .popUpWindow_divWorkOrder,
.block2_centralBlock_divWorkOrder:focus-within .popUpWindow_divWorkOrder,
.block3_centralBlock_divWorkOrder:focus-within .popUpWindow_divWorkOrder,
.block3_centralBlock_divWorkOrder:focus-within .popUpWindow_divWorkOrder {
    display: block;
}

/*
bottom block
*/
.bottomBlock_divWorkOrder {
    padding-top: 50px;
}



.buttonAra_bottomBlock_divWorkOrder button {
    height: 54px;
    width: 80vw;
    margin-left: 10vw;
    background-color: gainsboro;
    font-size: 20px;
    border-radius: 3px;
    border-color: #F5F5F9;
    margin-top: 15px;
}
.buttonAra_bottomBlock_divWorkOrder button:active {
    background-color: #DCDCDF;
}
.buttonAra_bottomBlock_divWorkOrder svg {
    width: 15px;
    height: 15px;
}