.WeHelp_Dashboard {
    color: blue;
}
.weHelpDiagram {
    margin-left: 100px;
    color: red;
}

[class^= diagram] {
    margin-left: 100px;
    padding-left: 25px;
    height: 600px;
    /*height: auto;*/
    overflow: hidden;
    margin-bottom: 100px;
    margin-top: 50px;
    transition: all 2s;
}

[class^= diagram] svg {
    overflow: visible;
    margin-bottom:  50px;
    margin-top: 17px;
}

[class^= diagram] svg [class^= rect], [class^= diagram] svg text {
    transition: opacity 2s;
}

[class^= circleRect] {
    cursor: pointer;
}
.oneDimensionalDiagram svg:has(.circleRect:hover) .circleRect:not(:hover) + rect,
.oneDimensionalDiagram svg:has(.circleRect:hover) .circleRect:not(:hover) + rect + text {
    opacity: 0.1;
 }
 .oneDimensionalDiagram .columnText {
    font-size: 14px;
    font-weight: normal;
}
.multidimensionalDiagram .columnText {
    font-size: 16px;
    font-weight: normal;
}
.columnGroupText {
    font-size: 12px;
    hyphens: auto;
}

.multidimensionalDiagram svg:has(.circleRect0:hover) rect:not(.rect0),
.multidimensionalDiagram svg:has(.circleRect0:hover) rect:not(.rect0) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect1:hover) rect:not(.rect1),
.multidimensionalDiagram svg:has(.circleRect1:hover) rect:not(.rect1) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect2:hover) rect:not(.rect2),
.multidimensionalDiagram svg:has(.circleRect2:hover) rect:not(.rect2) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect3:hover) rect:not(.rect3),
.multidimensionalDiagram svg:has(.circleRect3:hover) rect:not(.rect3) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect4:hover) rect:not(.rect4),
.multidimensionalDiagram svg:has(.circleRect4:hover) rect:not(.rect4) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect5:hover) rect:not(.rect5),
.multidimensionalDiagram svg:has(.circleRect5:hover) rect:not(.rect5) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect6:hover) rect:not(.rect6),
.multidimensionalDiagram svg:has(.circleRect6:hover) rect:not(.rect6) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect7:hover) rect:not(.rect7),
.multidimensionalDiagram svg:has(.circleRect7:hover) rect:not(.rect7) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect8:hover) rect:not(.rect8),
.multidimensionalDiagram svg:has(.circleRect8:hover) rect:not(.rect8) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect9:hover) rect:not(.rect9),
.multidimensionalDiagram svg:has(.circleRect9:hover) rect:not(.rect9) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect10:hover) rect:not(.rect10),
.multidimensionalDiagram svg:has(.circleRect10:hover) rect:not(.rect10) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect11:hover) rect:not(.rect11),
.multidimensionalDiagram svg:has(.circleRect11:hover) rect:not(.rect11) + text {
    opacity: 0.1;
}
.multidimensionalDiagram svg:has(.circleRect12:hover) rect:not(.rect12),
.multidimensionalDiagram svg:has(.circleRect12:hover) rect:not(.rect12) + text {
    opacity: 0.1;
}



/*
Здесь настройки для линейной диаграммы
*/
.lineDiagram circle, .lineDiagram [class^= line] {
    transition: opacity 2s;
}
.lineDiagram [class^= circle] {
    cursor: pointer;
}

.lineDiagram svg:has([class^= circle]:hover) [class^= circle]:not(:hover) + [class^= line],
.lineDiagram svg:has([class^= circle]:hover) [class^= circle]:not(:hover) + [class^= line] + g [class^= points] {
    opacity: 0.1;
}


.lineDiagram svg:has([class^= line]:hover) [class^= line]:not(:hover),
.lineDiagram svg:has([class^= line]:hover) [class^= line]:not(:hover) + g [class^= points] {
    opacity: 0.1;
}

[class^= points]:hover {
    cursor: pointer;
}
.subscription {
    display: none;
}
[class^= points]:hover + text {
    opacity: 1;
}

.lineDiagram svg:has(.points1:hover) [class^= points]:not(.points1),
.lineDiagram svg:has(.points1:hover) [class^= line]:not(.line1), 
.lineDiagram svg:has(.points2:hover) [class^= points]:not(.points2),
.lineDiagram svg:has(.points2:hover) [class^= line]:not(.line2),
.lineDiagram svg:has(.points3:hover) [class^= points]:not(.points3),
.lineDiagram svg:has(.points3:hover) [class^= line]:not(.line3),
.lineDiagram svg:has(.points4:hover) [class^= points]:not(.points4),
.lineDiagram svg:has(.points4:hover) [class^= line]:not(.line4),
.lineDiagram svg:has(.points5:hover) [class^= points]:not(.points5),
.lineDiagram svg:has(.points5:hover) [class^= line]:not(.line5),
.lineDiagram svg:has(.points6:hover) [class^= points]:not(.points6),
.lineDiagram svg:has(.points6:hover) [class^= line]:not(.line6),
.lineDiagram svg:has(.points7:hover) [class^= points]:not(.points7),
.lineDiagram svg:has(.points7:hover) [class^= line]:not(.line7),
.lineDiagram svg:has(.points8:hover) [class^= points]:not(.points8),
.lineDiagram svg:has(.points8:hover) [class^= line]:not(.line8),
.lineDiagram svg:has(.points9:hover) [class^= points]:not(.points9),
.lineDiagram svg:has(.points9:hover) [class^= line]:not(.line9),
.lineDiagram svg:has(.points10:hover) [class^= points]:not(.points10),
.lineDiagram svg:has(.points10:hover) [class^= line]:not(.line10),
.lineDiagram svg:has(.points11:hover) [class^= points]:not(.points11),
.lineDiagram svg:has(.points11:hover) [class^= line]:not(.line11),
.lineDiagram svg:has(.points12:hover) [class^= points]:not(.points12),
.lineDiagram svg:has(.points12:hover) [class^= line]:not(.line12),
.lineDiagram svg:has(.points13:hover) [class^= points]:not(.points13),
.lineDiagram svg:has(.points13:hover) [class^= line]:not(.line13),
.lineDiagram svg:has(.points14:hover) [class^= points]:not(.points14),
.lineDiagram svg:has(.points14:hover) [class^= line]:not(.line14),
.lineDiagram svg:has(.points15:hover) [class^= points]:not(.points15),
.lineDiagram svg:has(.points15:hover) [class^= line]:not(.line15),
.lineDiagram svg:has(.points16:hover) [class^= points]:not(.points16),
.lineDiagram svg:has(.points16:hover) [class^= line]:not(.line16),
.lineDiagram svg:has(.points17:hover) [class^= points]:not(.points17),
.lineDiagram svg:has(.points17:hover) [class^= line]:not(.line17) {
    opacity: 0.1;
}

.lineDiagram svg:has(.points1:hover) .points1 + .subscription,
.lineDiagram svg:has(.points2:hover) .points2 + .subscription,
.lineDiagram svg:has(.points3:hover) .points3 + .subscription,
.lineDiagram svg:has(.points4:hover) .points4 + .subscription,
.lineDiagram svg:has(.points5:hover) .points5 + .subscription,
.lineDiagram svg:has(.points6:hover) .points6 + .subscription,
.lineDiagram svg:has(.points7:hover) .points7 + .subscription,
.lineDiagram svg:has(.points8:hover) .points8 + .subscription,
.lineDiagram svg:has(.points9:hover) .points9 + .subscription,
.lineDiagram svg:has(.points10:hover) .points10 + .subscription,
.lineDiagram svg:has(.points11:hover) .points11 + .subscription,
.lineDiagram svg:has(.points12:hover) .points12 + .subscription,
.lineDiagram svg:has(.points13:hover) .points13 + .subscription,
.lineDiagram svg:has(.points14:hover) .points14 + .subscription,
.lineDiagram svg:has(.points15:hover) .points15 + .subscription,
.lineDiagram svg:has(.points16:hover) .points16 + .subscription,
.lineDiagram svg:has(.points17:hover) .points17 + .subscription {
    display: block;
}

.lineDiagram svg [class^= circle]:hover + [class^= line] + g .subscription {
    display: block;
}

.tick {
    font-size: 12px;
}




.description, .description_lineDiagram {
    display: block;
    padding-left: 42vw;
    background-color: silver;
    color: white;
    height: 60px;
    width: 100vw;
    padding-top: 15px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.input_wehelppage {
    opacity: 0;
    position: absolute;
    left: -9999px;
}

/*
.description:has(label:checked) {
    color: red;
}
*/
.description:has(input:checked) + div {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
}


/* Здесь настройка для контейнера с линейной диаграммой */
/*body .description_lineDiagram lineDiagram {*/
.lineDiagramInfo {
    margin-left: 100px;
    padding-left: 25px;
    height: 700px;
    margin-bottom: 20px;
    
    padding-top: 30px;
    padding-bottom: 90px;
    overflow: hidden;
    transition: all 2s;
}

.description_lineDiagram:has(input:checked) + div {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}



.buttonZoomPlus, .buttonZoomMinus {
    height: 29px;
    width: 29px;
    border: 1px solid silver;
    cursor: pointer;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    color: silver;
    margin-left: 81vw;
    opacity: 0.5;
}
.buttonZoomPlus {
    /*
    transform: translateY(-540px);
    */
    transform: translateY(-29vw) translateX(9vw);

    padding-left: 7px;
    padding-top: 1px;
}
.buttonZoomMinus {
    /*
    transform: translateY(-520px);
    */
    transform: translateY(-28vw) translateX(9vw);
    padding-left: 5px;
    padding-top: 1px;
}
.buttonZoomPlus:hover, .buttonZoomMinus:hover {
    opacity: 1;
    border-color: #FFAA20;
    color:#FFAA20;
    box-shadow: 7px 5px 5px silver;
}
.buttonZoomPlus:active, .buttonZoomMinus:active {
    box-shadow: 3px 5px 5px silver;
}