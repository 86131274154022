
.divWorkOrder .data_of_range {
    height: 50px;
    width: 100px;
    border: none;
    outline: 0;
    background-color: inherit;
    font-size: 20px;
    margin-left: 47vw;
    font-family: Comic Sans MS, Comic Sans, cursive;
    font-size: 24px;
}
.divWorkOrder .divOfRange {
    width: 80vw;
    height: 60px;
    margin-left: 10vw;
}
.divWorkOrder .leibleDivOfRange {
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    
}





/*
input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    /* width: 100%; /* Specific width is required for Firefox. */
    /* background: transparent; /* Otherwise white in Chrome */
/* }*/
/*  
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}
  
input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
/*}*/
 /*  
input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
  
    /* Hides the slider so custom styles can be added */
    /*background: transparent; 
    border-color: transparent;
    color: transparent;
}
/*



/*Стилизация большого пальца/*
/* Special styling for WebKit/Blink */
/*input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid bisque;
    height: 36px;
    width: 16px;
    border-radius: 10px;
    background-color: red;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
   /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
/*}*/
  
  /* All the same stuff for Firefox */
/*input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}*/
  
/* All the same stuff for IE */
/*
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
*/



/*Стилизация трека*/
/*
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
  
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
}
  
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
  
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
}
input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}
*/



/* Создание полного диапазона входных данных*/
input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #679FD2;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 2px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 10px;
    background: #FF8C00;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #679FD2;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #679FD2;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 10px;
    background: #FF8C00;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #679FD2;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: #679FD2;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #FF8C00;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #679FD2;
}
input[type=range]:focus::-ms-fill-upper {
    background: #679FD2;
}





