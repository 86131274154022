.App {
    overflow-x: hidden;
}
.WeHelpStatus {
    margin-top: 70px;
    font-weight: bold;
    color: red;
}
.PreTablePageConteiner {
    margin-top: 3vh;
}

.loader {
    width: 10vw;
    height: 10vw;
    position: absolute;
    top: 30vh;
    left: 45vw;
    animation-name: moveLoader;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    fill: #00d400;
    z-index: 9;
    margin-bottom: 100px;
}

.loaderPreTableChildPage {
    width: 10vw;
    height: 10vw;
    margin-left: 45vw;
    margin-top: 3vh;
    animation-name: moveLoader;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    fill: #00d400;
}

.svgLoaderStub {
    height: 30vh;
    width: 30vw;
}

@keyframes moveLoader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}

.childTableForm {
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    justify-content: space-around;
    max-width: 100%;

    margin-bottom: 15px;
    overflow-x: auto;
}
@media (max-height: 1300px) {
    .childTableForm {
        justify-content: normal;
    }
}
.altButton {
    flex-grow: 1;
    height: 60px;
    min-width: 250px;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
    object-fit: contain;

    display: flex;
    justify-content: center;
    padding-top: 15px;

    box-shadow: none;
    transition: box-shadow 1s;
    cursor: pointer;
}
.altButton:hover {
    background-color: #e3e2e7;
    
}
.activeAltButton {
    border-bottom: 2px solid #252733;
    box-shadow: 3px 5px 5px gray;
}

/*
.activeAltButton {
    box-shadow: 3px 5px 5px gray;
}

.inactiveAltButton {
    box-shadow: 3px 5px 5px gray;
}
*/


.buttonCloseTable {
    border: 1px solid #f66257;
    display: flex;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    cursor: pointer;
    align-items: center;

}
.buttonCloseTable span {
    margin-bottom: 5px;
}
.buttonCloseTable:hover {
    background-color: #f66257;
    box-shadow: 7px 5px 5px silver;
}
.buttonCloseTable:active {
    box-shadow: 3px 5px 5px silver;
}

.noDataTable {
    width: 80vw;
    display: flex;
    justify-content: center;
    margin-left: 10vw;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 35px;
    border-radius: 5px;
    padding-top: 7px;
    color: azure;
    font-weight: bold;
    font-size: 18px;
    background-color: #f66257;
}

/*
#B4BEC9 - серый
#4867D6 - голубой
#A78B71
*/



.tableControlForm {
    width: 94vw;
    margin-left: 2vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-right: 1vw;
}
.controlForm {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    border: 1px solid #4867D6;
    border-radius: 8px;
    cursor: pointer;
    
    margin-right: 15px;
}

.inactiveDeletion {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    border: 1px solid #4867D6;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 15px;
}
.generalUpdate, .deleteIndexedDB {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    border: 1px solid #4867D6;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 15px;
}
.controlForm:hover, .generalUpdate:hover, .deleteIndexedDB:hover {
    background-color: #4867D6;
    color: azure;
    box-shadow: 3px 5px 5px gray;
}
.controlForm:active, .generalUpdate:active, .deleteIndexedDB:active {
    box-shadow: 0px 0px 5px gray;
}
.deleteIndexedDB {
    text-align: center;
    padding-top: 1px;

}
.deleteIndexedDB:hover {
    border: 1px solid #f66257;
    background-color: #f66257;
}

.deleteModalWindow {
    position: absolute;
    background-color: #AECBF5;
    box-shadow: 5px 5px 5px 0 gray;
    font-family: Sarabun, sans-serif;
    width: 30vw;
    height: 17vh;
    left: 30vw;
    transform: translateY(153px);
    z-index: 9;
    opacity: 1;
    
}
.deleteModalWindow p {
    color: azure;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-top: 2vh;
}
.buttonsWrapping_deleteModalWindow {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 5%;
    
}
.buttonsWrapping_deleteModalWindow button {
    border-radius: 3px;
    border: 1px groove hsla(0,0%,75%, 1);
    width: 10vw;
    height: 50px;
    font-size: 18px;
}

.buttonsWrapping_deleteModalWindow button:active {
    box-shadow: inset 5px 5px 1px 0 gray;
}

.closeDeleteModalWindow {
    display: none;
}

/*
здесь определяется стиль для строк в таблице
!important нужен чтобы затереть стиль в таблице
и установить нужный стиль
*/
body .highlightedLine {
    background-color: #c8c7cc !important;
} 


/*
.PreTableChildPageConteiner {
    
    padding-bottom: 10px;
    padding-top: 10px;

    margin-bottom: 10px;
    background: linear-gradient(to right, transparent 4%, gold 4% 99%, transparent 99% 100%);
    border-radius: 5px;

}
*/
.PreTableChildPageConteiner, .PreTablePageConteiner {

    padding-bottom: 10px;
    padding-top: 10px;

    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 10px;
    border-radius: 15px;

}
.PreTablePageWrapping {
    width: 100vw;
    overflow-x: hidden;
}
/*
.PreTableChildPageConteiner {
    background-color: #dcdcde;
}
*/
/*
.borderForTable {
    position: absolute;
    height: auto;
    background-color: bisque;
    width: 95vw;
}
*/
/*
body tr[class^= highlightedLine]:hover {
    background-color: silver !important;
} 
body .css-131dqfq-MuiTableRow-root:has(td:hover)  {
    background-color: red !important;
} 
.css-131dqfq-MuiTableRow-root:active {
    background-color: silver !important;
} 
*/

/*
Проблемы с отображением alt form на других компьютерах.
Кнопка для закрытия таблицы смещается слишком сильно вправо.
Эта кнопка находится на расстоянии 3vw от правой стороны экрана, 
а значит все пропорции сильно изменены. Это подтверждает слишком 
большая высота top header. 
Может ли быть проблема в плотности пикселей?
Возможно, но я менял плотность пикселей в инструментах разработчика, 
но проблема так и не появлялась. 
Если в инструментах разработчика выбрать device toolbar, то все становится на свои места правильно.
Это не зависит от свойства dpr, который устанавливает плотность пикселей. 
Также проваливается наверх top menu на других компьютерах. 

Может чем меньше плотность пикселей тем больше расстояния?
Это может возникнуть при неправильной обработке соотношения пикселей браузером. 


body, app {
    overflou-x: hidden;
}

Похоже, что проюлема с вертикальным скроллбаром. Я устанавливаю ширину контейнера 100 vw и получается 
больше из за скроллбара. 
overflow: overlay не помогает. 
При удалении верхней таблицы, все приходит в норму. Alt form и таблица больше не съезжают. 
Кнопка съезжает но вероятно из за толстого скролл бара. 
Что ломает верстку при выведении нескольких таблиц. 
Проблема не найдена, я удалил все стили в консоли, но все работает также.
Нужно попробовать в обертке всех таблиц добавить еще одну обертку и поставить ей overflow hidden. 
top menu прячется из за неправильного медиа запроса, нужно добавить в параметры ширину экрана. 
*/