* {
box-sizing: border-box;
padding: 0px;
margin: 0px;
}
.top_header {
    background-color: #252733;
    height: 5vh;
    width: 100vw;
    top: 0;

    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 3001;
}
.hight_logo {
    color: white;
    font-family: Sarabun, sans-serif;
    font-weight: bolder;
    height: 5vh;
    width: 15vw;
    font-size: 23px;
    margin-left: 15px;
    margin-bottom: 20px;
    padding-bottom: 30px;
}
.logoc {
    width: 2.68vh;
    height: 3.76vh;
    transform: translateY(5px);
}
.sapLogo {
    width: 4.83vh;
    height: 2.68vh;
    transform: translateY(4px);
}
.hight_logo span {
    font-size: 2.47vh;
    display: inline-block;
    transform: translateY(-0.45vh);
}



.side_header {
    position: fixed;
    overflow-x: auto;
    overflow-y: auto;
    top: 0vh;
    left: 0px;
    height: 100vh;
    width: 3vw;
    background-color: #252733;
    transition: width 1s;
    z-index: 3000;
}
/*
.side_header:hover {
    width: 20vw;
}
.side_header:hover div {
    opacity: 1;
}
*/
.Home_page_link {
    padding-bottom: 7px;
}

.menuSimbol{
    font-size: 2.8vh;
    color: azure;
    cursor: pointer;
    transition: margin-left 1s, background-color 0.3s;
    width: 4.3vh;
    height: 4.3vh;
    border-radius: 50%;
    text-align: center;
    padding-top: 1px;
}
.menuSimbol:hover {
    background-color: rgba(228,236,252, 0.2);
}
.menuSimbol:active {
    background-color: rgba(228,236,252, 0.5);
}



.SecondaryLinks {
    overflow: hidden;
    transition: max-height 1s;
    border-left: 1px solid black;
}



@media (min-width: 1171px) and (max-width: 1341px) {
    .global_links div {
        font-size: 20px;
    }
}
@media (min-width: 1024px) and (max-width: 1171px) {
    .global_links div {
        font-size: 18px;
    }
}




/*
здесь настройки меню в верхней панели
*/
/*
.top_menu {
    height: 5vh;
    width: 97vw;
    left: 3vw;
    
    display: flex;
    position: fixed;
    top: 5vh;
    margin-left: 0;
    transition: backdrop-filter 1s;
    scrollbar-gutter: stable;
    overflow-x: auto;
    
    background-color: rgba(255, 255, 255, 0.1);
    
}
*/

.top_menu {
    height: 5vh;
    width: 100vw;
    
    display: flex;
    margin-top: 5vh;
    transition: backdrop-filter 1s;
    scrollbar-gutter: stable;
    overflow-x: auto;
    
    background-color: whitesmoke;
    
}

/*
.top_menu:hover {
    backdrop-filter: blur(5px);
    height: 7vh;
}
*/

.linksOfTopMenu {
    font-family: Sarabun, sans-serif;
    height: 5vh;
    font-size: 15px;
    padding-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    cursor: default;
    background-color: whitesmoke;
    width: 250px;
    
    overflow-wrap: anywhere;
    overflow: hidden;
    opacity: 0.7;
}

.linksOfTopMenu p {
    display: inline-block;
    width: 90%;
    padding-top: 5px;
    margin-right: 5px;
    font-size: 1.93vh;
}

.linksOfTopMenu p + div {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    flex-wrap: wrap; /* Позволяет элементам переходить на новую строку */
    height: 100%; 
}

.buttonLeftForTopMenu, .buttonRightForTopMenu {
    position: absolute;
    top: 5vh;
    height: 5vh;
    width: 3vw;
    background-color:  silver;
    z-index: 10;
    font-size: 35px;
    color: azure;
    padding-left: 1vw;
    padding-bottom: 2vh;
    border-radius: 45%;
    opacity: 0.3;
    transition: opacity 1s;
    cursor: pointer;

    /* это чтобы текст при нажатии мыши не выделялся*/
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.buttonLeftForTopMenu {
    left: 3vw;
}
.buttonRightForTopMenu {
    left: 97vw;
}
.buttonLeftForTopMenu:hover, .buttonRightForTopMenu:hover {
    opacity: 1;
    box-shadow: 1px 1px 1px gray;
}

.buttonLeftForTopMenu:active, .buttonRightForTopMenu:active {
    box-shadow: 0px 0px 0px gray;
}
.conteinerOfTopMenu {
    transition: margin-left 300ms;
}




/*
здесь настройки меню настроек пользователя
*/
.userMenu {
    display: flex;
    color: azure;
    margin-right: 20px;
    min-width: 160px;
    cursor: pointer;
}
.userMenu p {
    margin-left: 10px;
    margin-top: 12px;
    font-size: 18px;
}
.userMenu span {
    margin-left: 10px;
    margin-top: 15px;
    font-size: 12px;
}
.windowUnderLogo {
    position: absolute;
    top: 5vh;
    right: 20px;
    width: 160px;
    height: 90px;
    border: none;
    cursor: pointer;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    background-color: whitesmoke;
    color: #252733;
}
.windowUnderLogo div {
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
}
.windowUnderLogo div p {
    margin-left: 15px;
    margin-top: 5px;
    
}

/*
здесь настройки svg
*/

.svgFolder {
    height: 16px;
    width: 16px;
    fill: gray;
    stroke-width: 2px;
    margin-right: 5px;
}
.svgUser {
    margin-top: 5px;
    width: 40px;
    height: 40px;
    fill: azure;
   
}
.svgUser + p {
    font-size: 2.7vh;
}
.gear {
    margin-top: 5px;
    width: 25px;
    height: 25px;
    fill: black;
}
.exit {
    margin-top: 5px;
    width: 25px;
    height: 25px;
    fill: black;
}
.arrow {
    margin-top: 15px;
    margin-left: 10px;
    width: 3vh;
    height: 3vh;
    fill: azure;
}

/*
здесь настройки выпадающего меню
*/


.level0 {
    margin-top: 5px;
    margin-left: 10px;
    opacity: 1;
    transition: opacity 2s;
    cursor: default;
    color: white;
    font-family: Sarabun, sans-serif;
    font-weight: normal;
    font-size: 15px;
    min-width: 200px;

}

.secondaryLinks, .nameOfFolder {
    min-width: 300px;
}
.nameOfFolder:hover, .secondaryLinks:hover {
    font-style: bold;
    color: #008aef;
    height: auto;
}

.nameOfFolder {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 50px;
}

/*
.menuLabel:has(input:not(:checked)) + div {
    height: 0px;
    overflow: hidden;
}
*/
/*
.menuLabel:has(input:not(:checked)) + div {
    transform: scaleY(0);
    transform-origin: center top;
    height: 0px;
    transition: transform height 1s;
    
    overflow: hidden;
}
.menuLabel:has(input:checked) + div {
    border-left: 1px solid black;
    transform: scaleY(1);
    transform-origin: center top;
    transition: transform 1s;
    
}
*/
.menuLabel:has(input:not(:checked)) + div {

    overflow: auto; 
    max-height: 0; 
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);

}
.menuLabel:has(input:checked) + div {
    border-left: 1px solid black;
    max-height: 3000px; 
    transition: max-height 1s ease-in-out;
}



/*
здесь css меню для других браузеров, не chrom
*/


.menu_for_other_brausers [class^= level] {
    overflow: hidden;
}
.menu_for_other_brausers div {
    overflow: hidden;
}

.menu_for_other_brausers .menuLabel + div {
    margin-left: 10px;
    border-left: 1px solid black;
}


.menu_for_other_brausers .false {
    display: none;
}

.menu_for_other_brausers .true {
    display: block;
}

@-moz-document url-prefix(){
    .side_header {
        height: 100vh;
        overflow-y: auto;
    }
}




.headerSearchInputWrapping {
    max-width: 250px;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 15px;
    display: flex;
    overflow: hidden;
}

.headerMagnifyingGlass {
    height: 5.4vh;
    width: 5.4vh;
    fill: azure;
    cursor: pointer;
}
.inputWrapping_headerSearchInputWrapping {
    width: 200px;
    overflow: hidden;
    transition: width 1s;
}
.inputWrapping_headerSearchInputWrapping input {
    height: 4.29vh;
    width: 90%;
    margin-left: auto;
    padding-left: 5px;
    outline: 0;
    border-radius: 25px;
    background-color: azure;
    font-size: 16px;

}

.popUpWindow_headerDropDownList {
    min-height: 50px;
    min-width: 180px;
    position: absolute;
    z-index: 3001;
    background-image: linear-gradient(to bottom, silver, #f5f5f5 40px);
    transform: translateX(50px) translateY(5vh);
    clip-path: polygon(0 100%, 100% 100%, 100% 10px, 30% 10px, 25% 0, 20% 10px, 0 10px);
    padding-top: 15px;
    border-top: 1px solid black;
}

.popUpWindow_headerDropDownList div {
    height: 40px;
    border-bottom: 1px solid silver;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    cursor: pointer;
}

.popUpWindow_headerDropDownList div:hover {
    box-shadow: 2px 0px 5px 0 gray;
}

.popUpWindow_headerDropDownList div:active {
    box-shadow: inset 2px 0px 5px 0 gray;
}
