@media (min-width: 1401px) and (max-width: 1600px) {
    .homepage {
        grid-template-columns: 35vw;
    }
}
@media (min-width: 1205px) and (max-width: 1400px) {
    .homepage {
        grid-template-columns: 40vw;
    }
}
@media (min-width: 1165px) and (max-width: 1204px) {
    .homepage {
        grid-template-columns: 47vw;
    }
    .home_page_table1, .home_page_table2 {
        margin-left: 50px;
    }
}
@media (max-width: 1164px) {
    .homepage {
        margin-top: 30px;
        grid-template-rows: repeat(4, 1fr);
        column-gap: 0px;
        row-gap: 50px;
        justify-items: center;
        margin-bottom: 25px;
    }
    .home_page_table1, .home_page_table2 {
        margin-left: 0px;
    }
    .home_page_table1 {
        grid-row: 1;
    }
    .home_page_table3 {
        grid-row: 2;
        grid-column: 1;
    }
    .home_page_table2 {
        grid-row: 3;
    }
    .home_page_table4 {
        grid-row: 4;
        grid-column: 1;
    }

}
@media (max-width: 650px) {
    [class^=home_page_table] {
        width: 60vw;
        padding-right: 15px;
    }
    .home_page_table3_header, .home_page_table4_header {
        width: 100%;
    }
    .home_page_table3 button, .home_page_table4 button {
        top: 10px;
    }

}
@media (max-width: 301px) {
    .home_page_table1, .home_page_table2, 
    .home_page_table3, .home_page_table4 {
        height: 255px;
    }
    .home_page_table3 button, .home_page_table4 button {
        top: 5px;
    }
}
@media (min-width: 302px) and (max-width: 483px) {
    
    .home_page_table1, .home_page_table2, 
    .home_page_table3, .home_page_table4 {
        height: 230px;
    }
    .home_page_table3 button, .home_page_table4 button {
        top: 15px;
    }
}