
.loader {
    width: 10vw;
    height: 10vw;
    position: absolute;
    top: 30vh;
    left: 45vw;
    animation-name: moveLoader;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
    fill: #00d400;
}
@keyframes moveLoader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}