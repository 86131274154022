
.fildOfSettings {
    border: 1px solid red;
    background-color: whitesmoke;
}

.topBlock_fildOfSettings {
    width: 100vw;
    height: 390px;
    
}

.centralBlock_fildOfSettings {
    border: 1px solid greenyellow;
    height: 25vh;
    padding-top: 50px;
}

.label_topBlock_fildOfSettings {
    margin-left: 18vw;
    margin-top: 20px;
    margin-bottom: 10px;
    color: gray;
}

.old_password_topBlock_fildOfSettings, .new_password_topBlock_fildOfSettings,
.new_password_again_topBlock_fildOfSettings, .topBlock_fildOfSettings button {
    width: 65vw;
    height: 64px;
    border-radius: 3px;
    border: 1px solid hsla(0,0%,75%, 1);
    margin-left: 18vw;
    margin-top: 30px;
}


.topBlock_fildOfSettings button:active {
    box-shadow: inset 0px 0px 5px 0 gray;
}

.topBlock_fildOfSettings div input {
    width: 90%;
    height: 35px;
    margin-top: 5px;
    margin-left: 10px;
    outline: 0;
    border: none;
    font-size: 20px;
    background-color: whitesmoke;
    
}


.topBlock_fildOfSettings div input[class^=inactiveinput] {
    display: none;
}
.topBlock_fildOfSettings div:hover input[class^=inactiveinput], 
.topBlock_fildOfSettings div:focus-within input[class^=inactiveinput], 
.topBlock_fildOfSettings div input[class^=activeinput] {
    display: block;
}


.topBlock_fildOfSettings div p {
    transition: 
    font-size 1s,
    margin-top 1s,
    margin-left 1s;
}
.topBlock_fildOfSettings div p[class^=activeparagraph] {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 19px;
}

.topBlock_fildOfSettings div:hover p[class^=activeparagraph], 
.topBlock_fildOfSettings div:focus-within p[class^=activeparagraph],
.topBlock_fildOfSettings div p[class^=inactiveparagraph] {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}


.bottomBlock_divWorkOrder {
    padding-top: 50px;
}

.Warning_fildOfSettings {
    animation: blink 1s infinite;
    animation-direction: alternate;
}
@keyframes blink {
    from { border-color: whitesmoke;}
    to { border-color: red;}
}
.concurrencePasswords_fildOfSettings {
    border-color: #138808;
}



.testInput {
    margin-left: 300px;  
}
.testInput:checked {
    margin-left: 100px;  
}
.testInputLabel:has(input:checked) {
    color: red;
}
