
.middleComponentButton {
    background-color: #fff;
    border: none;
    border-radius: 5px;
    color: #007bff;
    font-size: 24px;
    width: 5vw;
    height: 3vh;
    margin-top: 2vh;
}
.middleComponentButton:hover {
    box-shadow: 3px 5px 5px gray;
}
.middleComponentButton:active {
    box-shadow: 0px 0px 5px gray;
}
.nameplate_middleComponent, .nameplate_MiddleComponentForThirdForm {
    background-color: #1f36c7;
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
.header_nameplate_middleComponent, .header_nameplate_MiddleComponentForThirdForm {
    font-size: 24px;
    padding-left: 40vw;
}
.lastText_nameplate_middleComponent, .lastText_nameplate_MiddleComponentForThirdForm {
    font-size: 24px;
    margin-left: auto;
    margin-bottom: 5px;
    margin-right: 3px;
}
.middleComponentConteiner, .MiddleComponentForThirdForm {
    overflow: hidden;
    transition: height 1s;
    background-color: #e9e9ee;
    padding-left: 10vw;
}
.middleComponentConteiner .wrappingFormTable {
    background-color: #ffffff;
    overflow: hidden;
}

.wrappingFormTable {
    margin-top: 10px;
    margin-bottom: 7px;
}

.emptyField {
    border: 2px solid #f66257;
}
.filledField {
    border: 2px solid green;
}

.signboard_middleComponentForThirdForm {
    width: 100vw;
    display: flex;
    overflow-x: scroll;
}
.sticker {
    min-width: 390px;
    height: 90px;
    background-color: #f8f8f8;
    color: #383839;
    margin: 3px;
    border-radius: 5px;
    border: 1.5px solid #b90505;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
}
.stickerAlt {
    min-width: 200px;
    min-height: 30px;
    padding-top: 5px;
    background-color: #f8f8f8;
    color: #383839;
    text-align: center;
    font-size: 18px;
    flex-grow: 1;
}
.stickerHeader {
    font-weight: 600;
}
.stickerAlt:hover {
    background-color: #d2e4f8;
}
.stickerAlt:hover .stickerHeader {
    color: #4fa2fc;
}


.MiddleComponentForThirdForm {
    padding-left: 2vw;
}

